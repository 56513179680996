import './dashboard-title.styles.scss';

const DashboardTitle = ({title}) => {
    return (
        <div className='title-container'>
            <h6 className='title-text'>{title}</h6> 
        </div>
    )
}

export default DashboardTitle