import PopupModal from "../_popup-modal/popup-modal.component"
import AdminModal from "../_user-list-modal-type/admin-modal.component";
import DeleteUserModal from "../_user-list-modal-type/delete-user-modal.component";
import ResetPasswordModal from "../_user-list-modal-type/reset-password-modal.component";
import { modalTypes } from "../_user-list-table/user-list-table.component";

const UserListModal = ({props}) => {

    const {userData, modal, setModal, reRender, setReRender} = props;
    
    return (
        <>
            <PopupModal closeModalState={{setCloseModal: setModal, closeModal: null}} >
                {
                    modal === modalTypes.admin &&
                    <AdminModal setModal={setModal} userData={userData} reRender={reRender} setReRender={setReRender} />      
                }
                {
                    modal === modalTypes.resetPassword &&
                    <ResetPasswordModal setModal={setModal} userData={userData} />                    
                }
                {
                    modal === modalTypes.deleteUser &&
                    <DeleteUserModal setModal={setModal} userData={userData} />                    
                }
            </PopupModal>
        </>
    )
}

export default UserListModal