import { deleteUser } from '../../utils/apiService';
import { useSelector } from 'react-redux';
import './user-list-modal-type.styles.scss';

const DeleteUserModal = ({userData, setModal}) => {
    const user = useSelector(state => state.auth.user);
    
    const handleCancel = () => {
        setModal(null);    
    }
    
    const handleConfirm = async () => {
        await deleteUser(userData.id, user.id);
        alert("User deleted successfully.");
        setModal(null);    
    }

    return (
        <div className='card-body-container'>
            <div className='title-container'>Delete User</div>
            <div className='DescriptionContainer'>
                Do you want to <span className="text-red-500 font-semibold">delete</span> {userData.name} ?
            </div>
            <div className='button-container'>
                <button className='cancel-button' onClick={handleCancel}>Cancel</button>
                <button className='confirm-button' onClick={handleConfirm}>Confirm</button>
            </div>
        </div>
    )
}

export default DeleteUserModal