// authActions.js

export const loginSuccess = (userDetails) => ({
    type: 'LOGIN_SUCCESS',
    payload: userDetails, 
  });

export const loginFailure = (errorMessage) => ({
    type: 'LOGIN_FAILURE',
    payload: errorMessage,
});

export const logout = () => ({
    type: 'LOGOUT',
});
