import DashboardTitle from "../../components/_dashboard-title/dashboard-title.component"
import ContentCard from "../../components/_content-card/content-card-component"
import './dashboard.styles.scss';
import { Link } from "react-router-dom"
import { pathUrl } from "../../data/navbar-link.data"
import StaffCountBySchool from "../../components/dashboards/StaffCountBySchool"
import StaffPositionBreakdown from "../../components/dashboards/StaffPositionBreakdown"
import { useEffect, useRef, useState } from "react";
import PopupModal from "../../components/_popup-modal/popup-modal.component";

const userDataList = {
  totalUser: 10,
  totalAdmin: 2,
};

const Dashboard = () => {
  const [modalWidth, setModalWidth] = useState(400);
  const [modalHeight, setModalHeight] = useState(400);
  
  // for the d3 canvas height and width
  const canvasRef = useRef(null);
  const canvasH = 300;
  const [canvasW, setCanvasW] = useState(null);

  useEffect(()=>{
    // get user device width and height
    // height
    const deviceHeight = window.innerHeight;
    if (deviceHeight < 500){
      setModalHeight(deviceHeight / 100 * 80);
    } else {
      setModalHeight(400);
    }
    // width
    const deviceWidth = window.innerWidth;
    if (deviceWidth < 1200) {
      setModalWidth(deviceWidth / 100 * 90);
    } else {
      setModalWidth(1200);
    }
    

    // get and set the initial value of the d3 canvas
    if (canvasRef.current) {
      const width = canvasRef.current.clientWidth;
      setCanvasW(width);
    }
    else {
      console.warn('canvasRef is exist, the canvas width is set to default 400px');
      setCanvasW(400);
    }
  },[])

  // for the modal when the graph is clicked by user
  const graphNameList = {
    staff_count_by_school: 'StaffCountBySchool',
    staff_position_breakdown: 'StaffPositionBreakdown',

  }
  const [modal, setModal] = useState(null);
  // onclick event to trigger modal 
  const toggleModal = (graphName) => {
    setModal(graphName);
  }

  return (
    <div className='body-container w-full'>
      <DashboardTitle title='Dashboard' />
      <div className="grid gap-4">        
        <div className='dashboard-grid'>
            <ContentCard classes="overflow-hidden">
                <div className='card-body' ref={canvasRef}>
                    <h6 className='title-text'>User List</h6>
                    <div className='ContentContainer'>
                      <div className='ContentItem'>
                          <div className='ItemNumber'>
                              {userDataList ? userDataList.totalUser : 0}
                          </div>
                          <div className='ItemTitle'>Total Users</div>
                      </div>
                      <div className='ContentItem'>                                
                          <div className='ItemNumber'>
                              {userDataList ? userDataList.totalAdmin : 0}
                          </div>
                          <div className='ItemTitle'>Total Admins</div>
                      </div>
                  </div>
                    <Link to={`/${pathUrl.userList}`} className='card-button'>Manage User List</Link>
                </div>                    
            </ContentCard>
            <ContentCard classes="overflow-hidden cursor-pointer select-none">
              <div onClick={()=>{toggleModal(graphNameList.staff_count_by_school)}}>
                {
                  canvasW && <StaffCountBySchool width={canvasW} height={canvasH}/> 
                }
              </div>
            </ContentCard>
            <ContentCard classes="overflow-hidden cursor-pointer select-none">
              <div onClick={()=>{toggleModal(graphNameList.staff_position_breakdown)}}>
                {
                  canvasW && <StaffPositionBreakdown width={canvasW} height={canvasH}/>
                }
              </div>
            </ContentCard>
        </div>        
      </div>
      {
        // toggle modal when graph chart is clicked
        modal && (
          <PopupModal closeModalState={{closeModal: false, setCloseModal: setModal}} >
            <div className="p-4 flex justify-center items-center">
              {
                (()=>{
                    switch (modal) {
                      case (graphNameList.staff_count_by_school):
                        return(
                          <StaffCountBySchool width={modalWidth} height={modalHeight}/>
                        );    
                      case (graphNameList.staff_position_breakdown):
                        return(
                          <StaffPositionBreakdown width={modalWidth} height={modalHeight}/>
                        );                      
                      default:
                        return 'Fail to load the graph.';
                    }
                  })()
              }
            </div>                      
          </PopupModal>
        )
      }
    </div>
  )
}

export default Dashboard