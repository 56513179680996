import './mobile-sidebar.styles.scss';

const MobileSidebar = () => {
  return (
    <div className="mobile-sidebar">
      <div className="mobile-sidebar-icon">
        <span class="material-icons-outlined">menu</span>
      </div>
    </div>
  )
}

export default MobileSidebar