import { resetUserPassword } from '../../utils/apiService';
import './user-list-modal-type.styles.scss';

const ResetPasswordModal = ({userData, setModal}) => {
    const handleCancel = () => {
        setModal(null);    
    }
    
    const handleConfirm = async () => {
        await resetUserPassword(userData.email);
        alert("Password reset successfully.");
        setModal(null);    
    }

    return (
        <div className='card-body-container'>
            <div className='title-container'>Reset Password</div>
            <div className='DescriptionContainer'>
                Do you want to reset {userData.name} password ?
            </div>
            <div className='button-container'>
                <button className='cancel-button' onClick={handleCancel}>Cancel</button>
                <button className='confirm-button' onClick={handleConfirm}>Confirm</button>
            </div>
        </div>
    )
}

export default ResetPasswordModal