import { Helmet, HelmetProvider } from "react-helmet-async";
import LoginPage from "../../components/login-page/login-page.component"
import { generateTitle } from "../../utils/generate-head.utils";

const Login = ({title}) => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{generateTitle(title)}</title>
        </Helmet>
      </HelmetProvider>
      <main>
        <LoginPage />
      </main>    
    </>
  )
}

export default Login