import './sidebar.styles.scss';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { navLinks } from '../../data/navbar-link.data';
import { useState } from 'react';
import MobileSidebar from '../_mobile-sidebar/mobile-sidebar.component';
import SidebarUserOption from '../_sidebar-user-option/sidebar-user-option.component';

const Sidebar = () => {

    const [isSidebarActive, setIsSidebarActive] = useState(false);

    const currentPath = useLocation();
    // console.log(currentPath.pathname);

    // function to close sidebar in mobile
    const handleCloseSidebar = () => {
        setIsSidebarActive(false);
    }

    // function to open sidebar in mobile
    const handleOpenSidebar = () => {
        setIsSidebarActive(true);
    }

    return (
        <main className="dashboard-container">
            <div className="sidebar-container">
                {/* mobile navbar */}
                <div className="mobile-sidebar-container" onClick={handleOpenSidebar}>
                    <MobileSidebar />
                </div>

                <div className={`nav-card ${isSidebarActive && 'active'}`}>

                    {/* mobile close icon */}
                    <div className="mobile-sidebar-close"  onClick={handleCloseSidebar}><span className="material-icons-outlined">close</span></div>
                    
                    <Link to='/'>
                        <div className='logo-container' onClick={handleCloseSidebar}>
                            <img className='logo-image' src='/images/logo/logo-light.svg' alt='logo' />
                        </div>
                    </Link>

                    <div className='nav-link-container'>
                    {
                        navLinks.map(navLink => (
                            <Link 
                                to={`/${navLink.path}`} 
                                className={`${currentPath.pathname === `/${navLink.path}` ? 'nav-active' : ''}`} 
                                key={navLink.name} 
                            >
                                <div className='nav-link' onClick={handleCloseSidebar}>
                                    <span className="nav-title z-20 material-icons">{navLink.icon}</span>
                                    <div className='nav-title z-20'>{navLink.name}</div>
                                    <div className={`$'hover-nav'`} ></div>
                                </div>
                            </Link>
                        ))
                    }
                    </div>
                    
                    <SidebarUserOption />

                </div>
            </div>
            <Outlet />
        </main>
    )
}

export default Sidebar