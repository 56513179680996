// apiService.js

import axios from 'axios';

const BASE_URL = 'https://backend.solutionmakers.app/api/';

//users
export const createUser = async (userData) => {

    try {
        const response = await axios.post(`${BASE_URL}user/create`, userData);

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const login = async (email, password) => {
    try {
        const response = await axios.post(`${BASE_URL}user/login`, { email, password });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const resetPassword = async (email) => {
    try {
        const response = await axios.post(`${BASE_URL}user/reset_password`, { email });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchUsers = async (email) => {
    try {
        const response = await axios.post(`${BASE_URL}users`, { email });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const resetUserPassword = async (email) => {
    try {
        const response = await axios.post(`${BASE_URL}user/reset_password`, { email });
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const promoteUserToAdmin = async (userId, updatedBy) => {
    try {
        const response = await axios.post(`${BASE_URL}user/promote`, { id: userId, updated_by: updatedBy });
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};
 
export const demoteUserFromAdmin = async (userId, updatedBy) => {
    try {
        const response = await axios.post(`${BASE_URL}user/demote`, { id: userId, updated_by: updatedBy });
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const deleteUser = async (userId, updatedBy) => {
    try {
        const response = await axios.post(`${BASE_URL}user/delete`, { id: userId, updated_by: updatedBy });
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

//dashboards
export const fetchStaffCountBySchool = async () => {
    try {
        const response = await axios.get(`${BASE_URL}dashboard/staff_count_by_school`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchStaffPositionBreakdown = async () => {
    try {
        const response = await axios.get(`${BASE_URL}dashboard/staff_position_breakdown`);
        return response.data; // This should be an array of positions and counts
    } catch (error) {
        throw error;
    }
};
