import './add-button.styles.scss'

const AddButton = ({backgroundClass='bg-theme-pink', colorClass='text-theme-white'}) => {
  return (
    <div className={`add-button-container ${backgroundClass}`} >
        <span class={`material-icons-outlined ${colorClass}`} >add</span>
    </div>
  )
}

export default AddButton