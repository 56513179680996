// src/components/DashboardComponent.js
import React, { useEffect, useState } from 'react';
import BarChart from './BarChart';
import { fetchStaffPositionBreakdown } from '../../utils/apiService';

const DashboardComponent = ({width, height}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchStaffPositionBreakdown()
        .then(data => {
            console.log("Fetched data:", data); // Log the data
            setData(data);
        })
        .catch(error => console.error('Error fetching data:', error));
  }, [width]);

  return (
    <div className='p-4'>
        <h2 className='font-bold'>Staff Position</h2>
        <BarChart 
            data={data} 
            xKey="actual_position" 
            yKey="count" 
            dimensions={{ width: width, height: height, margin: { top: 20, right: 30, bottom: 40, left: 90 } }}
        />

    </div>
  );
};

export default DashboardComponent;
