import { useRef } from 'react';
import './popup-modal.styles.scss';

const PopupModal = ({children, closeModalState}) => {

    const {setCloseModal, closeModal} = closeModalState;
    const modalRef = useRef(null);

    const handleClose = (e) => {
        if(modalRef.current.contains(e.target)) return;                
        setCloseModal(closeModal);
    }

    return (
        <div className='modal-container' onClick={handleClose}>
            <div className='card-container' ref={modalRef}>
                {children}                                            
            </div>
        </div>  
    )
}

export default PopupModal