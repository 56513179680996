import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './login-card.styles.scss';
import { useDispatch } from 'react-redux';
import { login as loginService, resetPassword } from '../../utils/apiService';
import { loginSuccess, loginFailure } from '../../actions/authActions';

const LoginCard = () => {
    const emailRef = useRef('');
    const passwordRef = useRef('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onSubmit = async (e) => {
        e.preventDefault();
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        
        try {
            const response = await loginService(email, password);
            if (response.message === "Login successful") {
                dispatch(loginSuccess(response.user)); 
                navigate('/');
            } else {
                dispatch(loginFailure('Login failed')); // Dispatch login failure action
            }
        } catch (error) {
            console.error('Error logging in:', error);
            dispatch(loginFailure('Error logging in. Please try again.')); // Dispatch login failure action
        }
    };

    const handleResetPassword = async () => {
        const email = emailRef.current.value;
        if (!email) {
            setErrorMessage('Please type in your email.');
            return;
        }

        try {
            const response = await resetPassword(email);
            alert('Password reset successful. Check your email for the new password.');
            console.log(response);
        } catch (error) {
            console.error('Error resetting password:', error);
            setErrorMessage('Error resetting password. Please try again.');
        }
    };

    return (
        <div className='login-card'>
            <form className='form-body' onSubmit={onSubmit}>
                <div className='input-container'>
                    <input type="email" className='form-input' required ref={emailRef}/>
                    <div className='placeholder-text'>Email</div>
                </div>

                <div className='input-container'>
                    <input type="password" className='form-input' required ref={passwordRef} />
                    <div className='placeholder-text'>Password</div>
                </div>                        

                {errorMessage && <div className='fail-message'>* {errorMessage}</div>}

                <div className='input-container'>
                    <button type="submit" className='submit-button'>
                        Log In
                    </button>
                </div>
            </form>

            <div className='reset-pw' onClick={handleResetPassword}>
                Reset Password
            </div>
        </div>
    );
};

export default LoginCard;
