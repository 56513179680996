import './user-list-table.styles.scss';
import { useEffect, useState } from 'react';
import UserListModal from '../_user-list-modal/user-list-modal.component';
import UserListPagination from '../_user-list-pagination/user-list-pagination.component';
import { fetchUsers } from '../../utils/apiService'; 
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import AddButton from '../_add-button/add-button.component';
import AddUserModal from '../_add-user-modal/add-user-modal.component';
// modal type constant
export const modalTypes = {
    admin: 'admin',
    resetPassword: 'resetPassword',
    deleteUser: 'deleteUser'
};

const UserListTable = () => {
    const [modal, setModal] = useState(null);
    const [addUser, setAddUser] = useState(false);
    const [userData, setUserData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataArray, setDataArray] = useState([]);
    const [reRender, setReRender] = useState(false);
    const [itemsPerPage] = useState(10);

    const toggleReRender = () => {
        setReRender(prev => !prev); // Toggle the state to force re-render
    };
    
    const user = useSelector(state => state.auth.user);
    
    const refreshUserList = useCallback(() => {
        if (user?.email) {
            fetchUsers(user.email)
                .then(data => setDataArray(data || []))
                .catch(error => console.error("Failed to fetch user data:", error));
        }
    }, [user?.email]); // Add dependencies here
    

    useEffect(() => {
        refreshUserList();
    }, [refreshUserList, currentPage, itemsPerPage, reRender]);

    const handleOnClickEvent = async (actionType, userData) => {
        setModal(actionType); 
        setUserData(userData);        
    };

    return (
        <div className='user-list-table-container'>
            <div className='table-container'>
                {dataArray.map((data) => (
                    <div className='content-card-container' key={data.id || data.username}>
                        <div className='table-column'>
                            <div className='table-item flex gap-2 items-center'>
                                {data.role === 'admin' ? (<span className="material-icons">verified</span>) : ''}
                                <span>{data.name}</span>
                            </div>
                            <div className='table-item'>{data.email}</div>
                            <div className='table-item'>{data.username}</div>
                            <div className='table-item table-action'>
                                <div className='action-item' data-modal={modalTypes.admin} onClick={() => handleOnClickEvent(modalTypes.admin, data)}>
                                    <span className={`material-icons ${data.role !== 'admin' && 'opacity-25'}`} data-modal={modalTypes.admin}>verified</span>
                                </div>
                                <div className={'action-item'} data-modal={modalTypes.resetPassword} onClick={()=>{handleOnClickEvent(modalTypes.resetPassword, data)}}>
                                    <span className="material-icons" data-modal={modalTypes.resetPassword}>lock_reset</span>
                                </div>
                                
                                <div className={'action-item'} data-modal={modalTypes.deleteUser} onClick={()=>{handleOnClickEvent(modalTypes.deleteUser, data)}}>
                                    <span className="material-icons" data-modal={modalTypes.deleteUser}>delete_forever</span>
                                </div>

                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {modal && <UserListModal props={{userData, modal, setModal, reRender, setReRender}} />}
            
            <div className="add-user-container">
                <span onClick={()=>{setAddUser(true)}}>
                    <AddButton />
                </span>
            </div>
            {addUser && <AddUserModal props={{setAddUser, toggleReRender}} />}

            <UserListPagination props={{data: dataArray, currentPage, setCurrentPage, itemsPerPage}} />
        </div>
    );
};

export default UserListTable;
