import React, { useState } from 'react';
import ModalInput from '../_modal-input/modal-input.component';
import ModalTitle from '../_modal-title/modal-title.component';
import PopupModal from '../_popup-modal/popup-modal.component';
import { useSelector } from 'react-redux';
import { createUser } from '../../utils/apiService';
import './add-user-modal.styles.scss';

const AddUserModal = ({props}) => {
    const { setAddUser, toggleReRender } = props;
    const user = useSelector(state => state.auth.user);
    const [formData, setFormData] = useState({
        name: '',
        username: '',
        email: '',
        created_by: user.email
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        console.log(formData)
        event.preventDefault();
        try {
            const data = await createUser(formData);
            console.log('User created:', data); 
            if(data.message === 'User created successfully')
            {
                setAddUser(false); 
                toggleReRender(); 
            }
            
        } catch (error) {
            console.error('Error creating user:', error);
        }
    };

    return (
        <PopupModal closeModalState={{ closeModal: false, setCloseModal: setAddUser }}>
            <form className="add-user-modal-container" onSubmit={handleSubmit}>
                <div>
                    <ModalTitle title='Add User' />
                </div>
                <div className='grid gap-1'>
                    <h4 className="input-title">Name:</h4>
                    <ModalInput type='text' name='name' placeholder='Full name' onChange={handleChange} value={formData.name}/>
                </div>
                <div className='grid gap-1'>
                    <h4 className="input-title">Username:</h4>
                    <ModalInput type='text' name='username' placeholder='Username' onChange={handleChange} value={formData.username}/>
                </div>
                <div className='grid gap-1'>
                    <h4 className="input-title">Email:</h4>
                    <ModalInput type='email' name='email' placeholder='example@email.com' onChange={handleChange} value={formData.email}/>
                </div>
                <button type='submit' className='submit-button'>ADD USER</button>
            </form>
        </PopupModal>
    );
}

export default AddUserModal;
