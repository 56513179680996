import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux'; // Import Redux hooks
import { pathUrl } from './data/navbar-link.data';
import 'material-icons/iconfont/filled.css';
import 'material-icons/iconfont/outlined.css';
import Login from './routes/login/login.component';
import Dashboard from './routes/dashboard/dashboard.component';
import Sidebar from './components/sidebar/sidebar.component';
import UserList from './routes/user-list/user-list.component';

function App() {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated); // Retrieve isAuthenticated state from Redux store
 
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>UTLC</title>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
          <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&display=swap" rel="stylesheet" />
        </Helmet>
      </HelmetProvider>

      {isAuthenticated ? (
  
          <Routes>
            <Route path='/' element={<Sidebar />}>
              <Route path="/" element={<Dashboard />} />
              <Route path={pathUrl.userList} element={<UserList />} />
            </Route>
          </Routes>

      ) : (
        <Routes>
          <Route path="/login" element={<Login title="Login" />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}

    </>
  );
}

export default App;
