import LoginCard from '../_login-card/login-card.component';
import './login-page.styles.scss';

const LoginPage = () => {
  return (
    <div className="body-container">
      <div className="image-container">
        <img src='/images/logo/logo.svg' className='logo-image' alt="logo" />
      </div>
      
      <div className="blob">
        <svg className='pink-blob' viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path fill="#FF0069" d="M47,-52.7C59.4,-45.7,66.8,-29.4,67,-13.9C67.2,1.5,60.2,16.1,52.5,31.1C44.8,46,36.3,61.2,22.9,68.6C9.5,75.9,-8.9,75.4,-22.3,67.7C-35.8,60.1,-44.3,45.3,-49.3,31.2C-54.3,17,-55.8,3.4,-53,-8.8C-50.2,-21,-43.1,-31.9,-33.5,-39.5C-24,-47,-12,-51.1,2.7,-54.3C17.3,-57.5,34.7,-59.8,47,-52.7Z" transform="translate(100 100)" />
        </svg>
      </div>

      <div className="login-card-container">   
        <LoginCard />
      </div>

    </div>
  )
}

export default LoginPage