import './content-card.styles.scss';

const ContentCard = ({children, classes=''}) => {
  return (
    <div className={`
      content-card-container
      ${classes}
    `}>
        {children}
    </div>
  )
}

export default ContentCard