import './modal-input.styles.scss';

const ModalInput = ({ type, placeholder = '', name, classname = '', onChange, value, req = true }) => {
    return (
        <input
            className={`modal-input ${classname}`}
            type={type}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            required={req}
        />
    );
}

export default ModalInput;
