import ContentCard from '../../components/_content-card/content-card-component';
import DashboardTitle from '../../components/_dashboard-title/dashboard-title.component';
import UserListTable from '../../components/_user-list-table/user-list-table.component';
import './user-list.styles.scss';

const UserList = () => {        
    return (
        <div className='body-container w-full relative'>
            <DashboardTitle title='User List' />              
            {/* Table Header */}
            <div className="overflow-x-auto pb-2 mb-20">
                <div className='table-container'>
                    <ContentCard>
                        <div className='table-header'>
                            <div className={`${'table-item'} `}>Name</div>
                            <div className={`${'table-item'} `}>Email</div>
                            <div className={`${'table-item'} `}>Username</div>
                            <div className={`${'table-item'}  ${'table-action'}`}>Action</div>
                        </div>
                    </ContentCard>
                </div>
                <UserListTable />    
            </div>        
        </div>
    )
}

export default UserList