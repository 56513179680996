import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // For React Router v6
import { logout } from '../../actions/authActions'; // Adjust the path as needed
import './sidebar-user-option.styles.scss';

const SidebarUserOption = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Hook for navigation

    const handleLogout = () => {
        dispatch(logout()); // Dispatch the logout Redux action
        navigate('/login'); // Redirect to the login page
    };

    return (
        <div className="sidebar-user-option-container">
            <div className="option-button" onClick={handleLogout}>
                <span className="material-icons">logout</span> {/* Correct class to className */}
            </div>
        </div>
    );
}

export default SidebarUserOption;
