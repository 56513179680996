import { useEffect, useRef, useState } from 'react';
import './user-list-pagination.styles.scss';

const UserListPagination = ({props}) => {
    const {data, currentPage, setCurrentPage, itemsPerPage} = props;
    
    const pageNumbers = useRef(data ? Math.ceil(data.length / itemsPerPage) : 1); 
    // const pageNumbers = data ? Math.ceil(data.length / itemsPerPage) : 1; 
    const pageNumbersArray = useRef(Array.from({ length: pageNumbers.current }, (_, index) => index + 1));
    // const pageNumbersArray.current = Array.from({ length: pageNumbers }, (_, index) => index + 1);        
    const [renderPageNumber, setRenderPageNumber] = useState([]);

    const handlePage = (index) => {
        setCurrentPage(index);
    }

    const handleMostPrev = () => {
        setCurrentPage(1);
    }

    const handleMostNext = () => {
        setCurrentPage(pageNumbersArray.current.length);
    }

    const handleChangePage = (index) => {
        setCurrentPage(currentPage + index);
    }

    useEffect(()=>{
        const updateRenderPage = () => {
            if( pageNumbersArray.current.length < 3 ) return setRenderPageNumber(pageNumbersArray.current);
            if (currentPage-1 <= 0) {
                setRenderPageNumber(pageNumbersArray.current.slice(0, 3));
            }
            else if (currentPage >= pageNumbersArray.current.length) {
                setRenderPageNumber(pageNumbersArray.current.slice(pageNumbersArray.current.length - 3, pageNumbersArray.current.length));
            } 
            else {
                setRenderPageNumber(pageNumbersArray.current.slice(currentPage-2, currentPage+1))
            }
        }

        updateRenderPage();
    }, [currentPage])

    return (
        <>
        {
            pageNumbers.current === 1 ? '' : (
                <div className='pagination-container'>
                    <div className={`${'pagination-button'} ${currentPage === 1 && 'disabled'}`} onClick={handleMostPrev}>
                        <span className="material-icons">keyboard_double_arrow_left</span>
                    </div>
                    <div className={`${'pagination-button'} ${currentPage === 1 && 'disabled'}`} onClick={()=>{handleChangePage(-1)}}>
                        <span className="material-icons">keyboard_arrow_left</span>
                    </div>
                

                    {   
                        pageNumbers.current &&
                        pageNumbersArray.current.map((page, index) => (
                            <div
                                className={`
                                    ${renderPageNumber.includes(page) ? '' : 'pagination-hide'}                            
                                    ${'pagination-button'} 
                                    ${currentPage === index+1 ? 'pagination-active' : ''}
                                `} 
                                key={index}
                                onClick={()=>{handlePage(index+1)}}
                            >
                                {page}
                            </div>
                        ))
                    }

                    <div className={`${'pagination-button'} ${currentPage === pageNumbersArray.current.length && 'disabled'}`} onClick={()=>{handleChangePage(1)}}>
                        <span className="material-icons">keyboard_arrow_right</span>
                    </div>
                    <div className={`${'pagination-button'} ${currentPage === pageNumbersArray.current.length && 'disabled'}`} onClick={handleMostNext}>
                        <span className="material-icons">keyboard_double_arrow_right</span>
                    </div>

                </div>   
            )
        }
        </>
    )
}

export default UserListPagination