import { demoteUserFromAdmin, promoteUserToAdmin } from '../../utils/apiService';
import { useSelector } from 'react-redux';
import './user-list-modal-type.styles.scss';

const AdminModal = ({userData, setModal, reRender, setReRender}) => {

    const user = useSelector(state => state.auth.user);
    
    const handleCancel = () => {
        setModal(null);    
    }
    
    const handleConfirm = async () => {
        if (userData.role === 'admin') {
            await demoteUserFromAdmin(userData.id, user.id);
            alert("User demoted from admin.");
        } else {
            await promoteUserToAdmin(userData.id, user.id);
            alert("User promoted to admin.");
        }
        setModal(null);
        setReRender(!reRender);
    }

    return (
        <div className='card-body-container'>
            {
                userData.role === 'admin' ? 
                (<div className='title-container'>Demote Admin</div>) :
                (<div className='title-container'>Promote Admin</div>) 
            }          
            <div className='DescriptionContainer'>
                Do you want to {userData.role ? 'demote' : 'promote'} {userData.name} ?
            </div>
            <div className='button-container'>
                <button className='cancel-button' onClick={handleCancel}>Cancel</button>
                <button className='confirm-button' onClick={handleConfirm}>Confirm</button>
            </div>
        </div>
    )
}

export default AdminModal