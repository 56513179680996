import React from 'react'

const ModalTitle = ({title}) => {
    return (
        <h1 className="font-bold text-[2rem]">
            {title}
        </h1>        
    )
}

export default ModalTitle