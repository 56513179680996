const initialState = {
  isAuthenticated: false,
  errorMessage: '',
  user: null, // Add a place to store user details
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isAuthenticated: true,
        errorMessage: '',
        user: action.payload, // Assume payload includes user details
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        isAuthenticated: false,
        errorMessage: action.payload,
        user: null, // Clear user details on failure
      };
    case 'LOGOUT':
      return {
        ...initialState // Reset to initial state on logout
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload, // Set user details
      };
    default:
      return state;
  }
};

export default authReducer;
