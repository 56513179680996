import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const BarChart = ({ data, xKey, yKey, dimensions }) => {
  const svgRef = useRef();

  useEffect(() => {
    if (data && data.length > 0) {
      const svg = d3.select(svgRef.current);
      svg.selectAll("*").remove(); // Clear SVG content before redrawing
      
      // Destructure or provide defaults for dimensions
      const { width = 800, height = 500, margin = { top: 20, right: 30, bottom: 40, left: 90 } } = dimensions;

      const x = d3.scaleBand()
        .domain(data.map(d => d[xKey]))
        .rangeRound([margin.left, width - margin.right])
        .padding(0.1);

      const y = d3.scaleLinear()
        .domain([0, d3.max(data, d => d[yKey])]).nice()
        .range([height - margin.bottom, margin.top]);

      const xAxis = g => g
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x));

      const yAxis = g => g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y));

      svg.append("g").call(xAxis);
      svg.append("g").call(yAxis);
      
      svg.append("g")
        .attr("fill", "steelblue")
        .selectAll("rect")
        .data(data).enter().append("rect")
          .attr("x", d => x(d[xKey]))
          .attr("y", d => y(d[yKey]))
          .attr("height", d => y(0) - y(d[yKey]))
          .attr("width", x.bandwidth());
    }
  }, [data, xKey, yKey, dimensions]); // Redraw chart if these props change

  return (
    <svg ref={svgRef} width={dimensions.width || 800} height={dimensions.height || 500} />
  );
};

export default BarChart;
